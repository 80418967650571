.content {
  min-width: 0;
}

.contentClosed {
  width: calc(100% - 70px) !important;
  transition-duration: 1.3s;
  transform: width;
  -webkit-transform: width;
  -webkit-transition-duration: 1.3s;
}

.asideHeader {
  flex: 0 1 auto;
  z-index: 20 !important;
}

.asideContent {
  // flex: 1 1 auto;
  flex-grow: 1;
  padding: 40px !important;
  overflow-y: auto;

  height: calc(100vh - 87px);

  &::-webkit-scrollbar {
    width: 9px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba($color: #89cfdf, $alpha: 0.4);
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: rgba($color: #89cfdf, $alpha: 0.8);
  }
}

.asideSecondContent {
  flex: 1 1 auto;
}

.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  padding: 0;
  width: calc(100% - 283px);
  // height: 100%;
  transition: all 0.8s ease-in-out;
}
