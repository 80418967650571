.title {
  color: #1f114d !important;
  font-weight: bold !important;
  font-size: 24px !important;
}

.unreadTitle {
  color: #3f465b;
  font-weight: bold;
  display: flex;
  flex-wrap: nowrap;
}

.toReadButton {
  color: #2fac66;
  cursor: pointer !important;
  &:hover {
    background-color: #2fac66;
    border-radius: 4px;
    color: white;
  }
}

.toDeleteButton {
  color: #e50046;
  cursor: pointer !important;
  &:hover {
    background-color: #e50046;
    border-radius: 4px;
    color: white;
  }
}

.listContent {
  & > div {
    width: 100% !important;
  }
  overflow: hidden !important;
}

.listVirtualizedContainer {
  position: relative !important;
  // overflow-x: scroll !important;
  & > div > div:nth-child(2) {
    position: unset !important;
  }
  & > div::-webkit-scrollbar {
    width: 6px;
    background: #f1f1f1;
  }
  & > div::-webkit-scrollbar-thumb {
    background: rgba($color: #00857f, $alpha: 0.4);
  }
}

.listVirtualizedContainerContent {
  & > div {
    position: unset !important;
    margin-right: 20px !important;
  }
}

.modalContent {
  background-color: transparent !important;
  border: none !important;
  width: 80px;
}

.navItem {
  height: 26px;
  width: 26px;
  color: #1f114d;
  display: flex;
  
  &:hover {
    color: #fbba00 !important;
  }
}
