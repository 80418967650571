.tag {
  background-color: #f4f4f4;
  border: 1px solid #e9e9e9;
  padding: 2px 0 2px 2px;
  border-radius: 2px;
  display: inline-block;
}
.tag:focus-within {
  background-color: #e9e9e9;
  border-color: #a0a0a0;
}
.tag-remove {
  color: #a0a0a0;
  font-size: 75%;
  line-height: 100%;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}
.tag-remove.disabled,
.tag-remove.readOnly {
  cursor: not-allowed;
}
.tag-remove:focus {
  color: #3c3c3c;
}
.node > label {
  cursor: pointer;
  margin-left: 2px;
}
.tag-list {
  display: inline;
  padding: 0;
  margin: 0;
  width: 99%;
}
.tag-item {
  display: inline-block;
  margin: 4px;
  // width: 92%;
  &:last-child {
    width: 92%;
    margin-right: 4px;
  }
}

.tag-item .search {
  border: none;
  border-bottom: 0px solid #ccc;
  outline: none;
  width: 100%;
  background-color: transparent;
}

.node {
  list-style: none;
  white-space: nowrap;
  padding: 4px;
}
.node.leaf.collapsed {
  display: none;
}
.node.disabled > * {
  color: gray;
  cursor: not-allowed;
}
.node.match-in-children.hide .node-label {
  opacity: 0.5;
}
.node.focused {
  background-color: #f4f4f4;
}
.toggle {
  white-space: pre;
  margin-right: 4px;
  cursor: pointer;
}
.toggle:after {
  content: " ";
}
.toggle.collapsed:after {
  content: "+";
}
.toggle.expanded:after {
  content: "-";
}
.searchModeOn .toggle {
  display: none;
}
.checkbox-item,
.radio-item {
  vertical-align: middle;
  margin: 0 4px 0 0;
}
.checkbox-item.simple-select,
.radio-item.simple-select {
  display: none;
}
.hide:not(.match-in-children) {
  display: none;
}

.rbt-input-multi.form-control {
  // border-right: none;
  background-color: #ffffff;
  padding-right: 2rem;
}

.modal-content {
  border-radius: inherit;
}

.modal-footer {
  border-top: none;
  justify-content: center;
}

.modal-header {
  border-top: none;
  border-bottom: none;
}

.form-control.is-invalid {
  background-image: none;
  outline: none !important;
  box-shadow: none !important;
}
.form-control.is-invalid.focus {
  outline: none !important;
  box-shadow: none !important;
}

.rbt-input-multi {
  z-index: 0;
}

@media (max-width: 991px) {
  .nav-left-custom {
    display: none;
  }
}

.rbt-aux {
  display: none;
}

.rbt-menu.dropdown-menu.show {
  max-height: 190px !important;
  width: 100% !important;
}

.rbt-input-multi.focus {
  border: 1px solid #ced4da;
  box-shadow: none;
  color: unset;
}
