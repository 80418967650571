@import "./screen-variable";
@import "./responsive";
@import "./settings.scss";
@import "./custom.scss";

html,
body,
#root {
  height: 100%;
  overflow: hidden !important;
  background: #ffffff;
}
main {
  height: 100%;
  // overflow: hidden !important;
}

.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
/*.dropdown-menu{min-width: auto!important;}*/
.logoSeparator {
  // text-align: center;
  img {
    width: 160px;
  }
}
.form-control:focus {
  color: #3f465b;
  border-color: #9fa3ad;
  outline: 0;
  box-shadow: none !important;
}

.toggle-button {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}

.toggle-button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
}

.form-check-input:focus {
  border-color: #9fa3ad;
  outline: 0;
  box-shadow: none !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
  background-color: #00857f;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
  background-color: #e58b4c;
}
.Toastify__toast {
  font-size: 0.925rem;
}
a[id^="client-item-"] {
  font-size: 14px !important;
}
.ant-select {
  font-family: "Conduits";
}
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
  border-color: transparent !important;
  margin-top: 2px;
}
.ant-select-selector:focus {
  border: 2px solid blue;
}
.border-green-light {
  border-color: #89cfdf !important;
}
.border-primary-green {
  border-color: #00857f !important;
}

::-webkit-scrollbar {
  width: 6px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgba($color: #00857f, $alpha: 0.4);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba($color: #89cfdf, $alpha: 0.8);
}

img,
svg {
  vertical-align: unset !important;
}
