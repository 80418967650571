.headerPhoneBook {
  width: auto;
  display: flex !important;
  align-items: center !important;
  gap: 16px;
}

@media (min-width: 576px) {
  .navBarHeaderContainer {
    justify-content: space-between !important;
  }
  .navBarCol {
    width: unset !important;
  }
}

.navItem {
  height: 20px;
  width: 20px;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.navItemRefacto {
  width: 100%;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
}

.navItemIcon {
  height: 20px;
  width: 20px;
  padding: 0 !important;
  margin: 0 !important;
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.navItem:hover {
  color: red;
}

.navItemMD {
  height: 30px;
  width: 30px;
  color: #1f114d;

  &:hover {
    color: #fbba00 !important;
  }
}

.navItemSm {
  height: 28px;
  width: 28px;
  color: #1f114d;

  &:hover {
    color: #fbba00 !important;
  }
}

.itemText {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  margin-left: 15px;
  color: #1f114d;
}

.phoneBookOpen {
  opacity: 0;
  visibility: hidden;
  width: 0% !important;
  -webkit-transition: width 600ms, opacity 600ms, visibility 600ms;
  transition: width 600ms, opacity 600ms, visibility 600ms;
}

.phoneBookClosed {
  opacity: 1;
  width: 16% !important;
  visibility: visible;
}

.phoneBookOpenShow {
  z-index: 0 !important;
  opacity: 0;
  transform: translateX(-700px);
  -webkit-transform: translateX(-700px);
  transition: transform 800ms ease-in-out, opacity 600ms, visibility 800ms;
  -webkit-transition: transform 800ms ease-in-out, opacity 600ms,
    visibility 800ms;
  visibility: hidden;
}

.phoneBookClosedShow {
  z-index: 0 !important;
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateX(0);
  transition: transform 800ms ease-in-out, opacity 600ms, visibility 800ms;
  -webkit-transition: transform 800ms ease-in-out, opacity 600ms,
    visibility 800ms;
}
