.textLogoShow {
  opacity: 1;
  transition-property: opacity;
  transition-duration: 1s;
}

.textLogoHidden {
  opacity: 0;
  transition-property: opacity;
  transition-duration: 1s;
}
