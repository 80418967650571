.profile {
  margin-right: 50px;

  a {
    padding: 0;
    min-width: 30px;
  }

  div {
    padding-left: 10px;
  }
}

.image {
  border-radius: 50%;
  width: 250px;
  height: 250px !important;
  object-fit: cover;
  border: 1px solid #e6e6e683;
  margin: 0;
}

.imageProfile {
  width: 250px;
  height: 250px !important;
}

.containerData {
  background: white;
  border-radius: 3px;
  margin-bottom: 40px;

  .header {
    border-bottom: 1px solid #dad8d8;
    padding: 15px 20px;
    margin-bottom: 20px;
  }

  p:first-of-type {
    margin-bottom: 5px;
  }

  p:last-child {
    font-weight: bold;
    min-height: 24px;
    cursor: pointer;
  }
}

.border {
  border-bottom: 1px solid #dad8d8;
  margin: 15px 20px;
  padding: 0 !important;
}

.update {
  cursor: pointer;

  &:hover {
    color: #ec6608;
  }
}

.imageIcons {
  color: white;
  position: absolute;
  background: #00857f;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  right: -12px;
  z-index: 99;
}

.iconDelete {
  top: 50%;
}

.iconEdit {
  top: calc(50% - 50px);
}

.iconEditCenter {
  top: calc(50% - 30px);
}

.profilName {
  color: #1f114d;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 18px;
}

.biography {
  max-width: 500px;
  color: #1f114d;
  font-style: normal;
  font-family: "Conduits-Light";
  font-size: 14px;
  line-height: 13px;
  margin: 20px 0px;
}

.avatarSkeleton {
  width: 250px !important;
  height: 250px;
  border-radius: 100% !important;
}
