/* App settings */
@font-face {
  font-family: "Conduits";
  src: url("../fonts/ConduitITCStd-Regular.woff") format("opentype");
}
@font-face {
  font-family: "Conduits-Light";
  src: url("../fonts/ConduitITCStd-Light.otf") format("opentype");
}
$primary: #3f465b;
$secondary: #b7b7b7;
$orange: #ec6608;
$purple: #524c9a;
$yellow: #fbba00;
$font-family-sans-serif: "Conduits", sans-serif;
$body-bg: #f7f7f7;
$body-color: $primary;
$border-color: #999999;
$border-radius-lg: 3rem; // class : rounded-3
$input-placeholder-color: $primary;
$light: white;
$font-size-base: 1rem; // Assumes the browser default, typically `16px`
$h1-font-size: $font-size-base * 3;

$font-sizes: (
  1: $h1-font-size,
);

$display-font-sizes: (
  1: 5rem,
  2: 4.5rem,
  3: 4rem,
  4: 3.5rem,
  5: 3rem,
  6: 2.5rem,
  7: 1.3rem,
);
$dropdown-font-size: 1.2rem;
$display-font-weight: 300;

$navbar-light-color: $primary;
$navbar-default-link-active-color: $orange;
$navbar-default-link-color: $primary;
$navbar-default-link-hover-color: $orange;
$navbar-light-hover-color: $orange;
$navbar-light-active-color: $orange;
$nav-link-padding: 0rem;
$theme-colors: (
  "orange": #e8b05f,
  "white": #ffffff,
  "orange-100": #ec6608,
  "purple": #673ab7,
  "gray": #b7b7b7,
  "light": #ffffff,
  "primary": #3f465b,
  "secondary": #b7b7b7,
  "danger": red,
);
$input-height-border: 40px;
$input-padding-x: 15px;
$form-floating-height: add(3.5rem, $input-height-border);
$form-floating-line-height: 1.25;
$form-floating-padding-x: $input-padding-x;
$form-floating-padding-y: 1rem;
$form-floating-input-padding-t: 1.625rem;
$form-floating-input-padding-b: 0.625rem;
$form-floating-label-opacity: 0.65;
$form-floating-label-transform: scale(1) translateY(-1.5rem) translateX(0.15rem);
$form-floating-transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;

/*End App settings*/

@import "~bootstrap/scss/bootstrap";
