.sidenav-common {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  z-index: 5;
  padding-top: 28px;
  padding-left: 16px;
  padding-right: 16px;
  background-color: #00857f;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.sidenav {
  @extend .sidenav-common;
  min-width: 283px;
  max-width: 283px;
  height: 100%;
  overflow-y: auto;
}

.sidenavClosed {
  @extend .sidenav-common;
  min-width: 80px;
  max-width: 80px;
}

.sideitem {
  display: flex;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  font-weight: 700;
  align-items: center;
  padding: 16px;
  border-radius: 8px;

  &:hover {
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 100%
    );
    color: #ffffff;
  }
}

.sideItemActive {
  display: flex;
  cursor: pointer;
  color: #ffffff;
  text-decoration: none;
  font-size: 20px;
  overflow: hidden;
  font-weight: 700;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.2) 0%,
    rgba(0, 0, 0, 0) 100%
  );

  &:hover {
    color: #ffffff;
  }
}

.linkText {
  white-space: nowrap;

  &:active {
    color: #ffffff !important;
  }
}

.linkTextFoldable {
  opacity: 1;
  color: #ffffff;
  font-family: "Conduits";
  font-weight: 700;
  font-size: 20px;
  align-items: center;
  line-height: initial;
  white-space: nowrap;
}

.linkTextFoldableHide {
  display: none;
  opacity: 0;
  color: #ffffff;
  font-family: "Conduits";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  align-items: center;
  -webkit-transition: opacity 600ms, visibility 600ms;
  transition: opacity 600ms, visibility 600ms;
}

.menuBtn {
  padding: 16px;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  margin-top: auto;
  margin-bottom: 32px;
  color: #b2bac2;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none !important;
  user-select: none;
  offset: 0;
  overflow: hidden;
}

.logoLincoln {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  transition-timing-function: ease-in-out;
}

.logoLincolnTranslate {
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  -webkit-transform: translate(-150px, 0);
  -moz-transform: translate(-150px, 0);
  -o-transform: translate(-150px, 0);
  -ms-transform: translate(-150px, 0);
  transform: translate(-150px, 0);
}

.hideLabel {
  display: none;
}
